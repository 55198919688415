import { useEffect, useState } from 'react'
import './schoolInformation.css'
import { QRCode } from 'react-qrcode-logo'
import QrLogo from '../assets/logo_FFJM_moyen.png'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import axios from '../axios/axios'
import { iEstablishementUser, iRegistration, iTypeCenter } from '../types/user'
import ParticipantLayout from './layouts/ParticipantLayout'
import { eStatus } from '../types/enums'
import Input from '../components/forms/common/Input'

function SchoolInformation() {
    const [estimatePlaces, setEstimatePlaces] = useState(0)
    const [host, setHost] = useState(false)
    const [visible, setVisible] = useState(false)
    const [selfExamCenter, setSelfExamCenter] = useState<iTypeCenter | null>(
        null
    )

    const [data, setData] = useState<{
        rneCode?: string | undefined
        name?: string | undefined
        street?: string | undefined
        city?: string | undefined
        zipCode?: string | undefined
        country?: string | undefined
        firstname?: string | undefined
        lastname?: string | undefined
        email?: string | undefined
        phone?: string | undefined
        estimatedParticipants?: number
        proposeToBeHalfExamCenter?: boolean | undefined
        controlledCenter?: iTypeCenter[]
        registrations: iRegistration[]
        visible?: boolean
    }>()

    function fetchData() {
        axios
            .get('/users/me')
            .then((res: { data: iEstablishementUser }) => {
                console.log(res)
                setData({
                    rneCode: res.data.establishment.rneCode,
                    name: res.data.establishment.name,
                    street: res.data.establishment.street,
                    city: res.data.establishment.city,
                    zipCode: res.data.establishment.zipCode,
                    country: res.data.establishment.country,
                    firstname: res.data.contact.firstname,
                    lastname: res.data.contact.lastname,
                    email: res.data.contact.email,
                    phone: res.data.contact.phone,
                    estimatedParticipants:
                        res.data.establishment.estimatedParticipants,
                    proposeToBeHalfExamCenter:
                        res.data.establishment.proposeToBeHalfExamCenter,
                    controlledCenter: res.data.controlledCenter,
                    registrations: res.data.establishment.registrations || [],
                    visible: res.data.establishment.visible,
                })
                setEstimatePlaces(
                    res.data.establishment.estimatedParticipants || 0
                )
                setHost(
                    res.data.establishment.proposeToBeHalfExamCenter || false
                )
                setVisible(res.data.establishment.visible || false)
                if (res.data.controlledCenter) {
                    setSelfExamCenter(
                        (res?.data?.controlledCenter?.length > 0 &&
                            res?.data?.controlledCenter[0]) ||
                            null
                    )
                }
            })
            .catch()
    }
    useEffect(() => {
        document.title = 'Informations'
        fetchData()
    }, [])

    function changeEstimatePlaces() {
        console.log('HOST')
        axios
            .patch('/establishement/me', {
                estimatedParticipants: estimatePlaces,
                proposeToBeHalfExamCenter: host,
                visible: visible,
            })
            .then((res) => {
                fetchData()
            })
    }
    const navigate = useNavigate()

    function download() {
        const canvas = document.getElementById(
            'qr-code-hid'
        ) as HTMLCanvasElement

        const url = canvas.toDataURL('image/png')

        const link = document.createElement('a')
        link.download = 'QR_code_inscription_FFJM.png'
        link.href = url
        link.click()
    }

    return (
        <ParticipantLayout color="red">
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'center',
                    justifySelf: 'center',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div className="columns">
                        <h1 className="infos-title">Informations</h1>
                        <div className="inputs-infos">
                            <span className="inputs-title">Nom :</span>
                            <span className="inputs-infos-data">
                                {data?.name}{' '}
                                {data?.rneCode && '(' + data.rneCode + ')'}
                            </span>
                        </div>

                        <div className="inputs-infos">
                            <span className="inputs-title">Adresse :</span>
                            <span className="inputs-infos-data">
                                {data?.street}, {data?.city} {data?.zipCode},{' '}
                                {data?.country}
                            </span>
                        </div>

                        <div className="inputs-infos">
                            <span className="inputs-title">
                                Paiement de l'adhésion :
                            </span>
                            <span className="inputs-infos-data flex">
                                {data?.registrations.map((reg) => (
                                    <span key={reg.year}>
                                        {reg.year} :{' '}
                                        {reg.status === eStatus.PENDING
                                            ? 'En attente de paiement'
                                            : 'Payé'}
                                    </span>
                                ))}
                            </span>
                        </div>
                        <Input
                            title="Nombre estimé de participants aux Quart de Finale"
                            value={estimatePlaces}
                            onChange={(e) =>
                                setEstimatePlaces(parseInt(e.target.value) || 0)
                            }
                            type="number"
                            required
                            error={
                                !estimatePlaces
                                    ? 'Le nombre de participants doit être indiqué'
                                    : undefined
                            }
                        />

                        <div className="insc-inputs-checkbox ">
                            <input
                                className="chexkbox-insc"
                                onChange={() => {
                                    setHost(!host)
                                }}
                                type="checkbox"
                                checked={host}
                            />
                            <span className="inputs-title-insc">
                                L'établissement propose d'être centre de
                                demi-finale
                            </span>
                        </div>

                        <div className="insc-inputs-checkbox ">
                            <input
                                className="chexkbox-insc"
                                onChange={() => {
                                    setVisible(!visible)
                                }}
                                type="checkbox"
                                checked={visible}
                            />
                            <span className="inputs-title-insc">
                                Ouvert à l'inscription des qualifiés scolaires
                            </span>
                        </div>

                        {(estimatePlaces !== data?.estimatedParticipants ||
                            host !== data?.proposeToBeHalfExamCenter ||
                            visible !== data?.visible) && (
                            <button
                                className="buttons-blue school-button save"
                                id="butt-en-school"
                                onClick={changeEstimatePlaces}
                            >
                                Enregistrer
                            </button>
                        )}

                        <h1 className="infos-title">Personne en charge</h1>
                        <div className="inputs-infos">
                            <span className="inputs-title">
                                {data?.lastname} {data?.firstname}
                            </span>
                            <span className="inputs-infos-data">
                                <br />
                                {data?.email} <br /> {data?.phone}
                            </span>
                        </div>
                    </div>
                    <div className="columns" style={{ alignItems: 'center' }}>
                        {selfExamCenter != null && (
                            <div className="text-center">
                                <button
                                    className="buttons-blue school-button school-doc-button"
                                    onClick={() =>
                                        navigate(
                                            `/etablissement/examcenter/${selfExamCenter.examCenterId}`
                                        )
                                    }
                                >
                                    Interface centre d'épreuve
                                </button>
                            </div>
                        )}
                        {/* {data?.} */}

                        <h1 className="infos-title">
                            Inscription des qualifiés
                        </h1>
                        <div id="qr-code-cont" onClick={download}>
                            <QRCode
                                id="qr-code-hid"
                                value={`https://${window.location.hostname}/Inscription?etablissement=${data?.rneCode}`}
                                size={3000}
                                quietZone={0}
                                fgColor="#f06e38"
                                logoImage={QrLogo}
                                logoHeight={1900}
                                logoWidth={1900}
                                logoOpacity={0.3}
                                qrStyle="dots"
                                ecLevel="H"
                                eyeRadius={[
                                    [10, 10, 0, 10],
                                    [10, 10, 10, 0],
                                    [10, 0, 10, 10],
                                ]}
                                eyeColor={[
                                    { outer: '#ed3b56', inner: '#face48' },
                                    { outer: '#ed3b56', inner: '#face48' },
                                    { outer: '#ed3b56', inner: '#face48' },
                                ]}
                            />
                            <QRCode
                                id="qr-code"
                                value={`https://${window.location.hostname}/Inscription?etablissement=${data?.rneCode}`}
                                size={300}
                                quietZone={0}
                                fgColor="#f06e38"
                                logoImage={QrLogo}
                                logoHeight={190}
                                logoWidth={190}
                                logoOpacity={0.3}
                                qrStyle="dots"
                                ecLevel="H"
                                eyeRadius={[
                                    [10, 10, 0, 10],
                                    [10, 10, 10, 0],
                                    [10, 0, 10, 10],
                                ]}
                                eyeColor={[
                                    { outer: '#ed3b56', inner: '#face48' },
                                    { outer: '#ed3b56', inner: '#face48' },
                                    { outer: '#ed3b56', inner: '#face48' },
                                ]}
                            />
                        </div>
                        <span id="qr-code-link" style={{ textAlign: 'center' }}>
                            {`https://${window.location.hostname}/Inscription?etablissement=${data?.rneCode}`}
                        </span>
                        <button
                            className="buttons-blue school-button school-doc-button"
                            onClick={() =>
                                navigate('/Etablissement/Participants')
                            }
                        >
                            élèves qualifiés inscrits
                        </button>
                        <h1 className="infos-title">Documentation</h1>
                        <a
                            target="_blank"
                            className="buttons-blue school-button school-doc-button school-documentation-button"
                            href="https://ffjmath.sharepoint.com/:f:/s/ChampionnatFFJM/Eny6ilutdqBGnvuf2pCcqJwBSH2hb3sYDUbbNRyYcR0a4g?e=ASe2PA"
                        >
                            Documentation générale
                        </a>
                        <a
                            target="_blank"
                            className="buttons-blue school-button school-doc-button school-documentation-button"
                            href="https://ffjmath.sharepoint.com/:f:/s/ChampionnatFFJM/Eplk9u2LFTNElXi4DWmoIVIBX5sKttWh8B6C_idRwahFcw?e=ziieIP"
                        >
                            Documentation quart de finale
                        </a>
                    </div>
                </div>
            </div>
        </ParticipantLayout>
    )
}
export default SchoolInformation
