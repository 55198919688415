import { useEffect, useState } from 'react'
import { getCategories } from '../../helpers/getCategories'
import Select from 'react-select'
import {
    iEstablishementWContact,
    iEstablishment,
    iRegistration,
} from '../../types/user'
import axios from '../../axios/axios'
import { toast } from 'react-toastify'

function RenewInscriptionModal({
    lastRegistration,
    closeModal,
}: {
    lastRegistration?: iRegistration
    closeModal: () => void
}) {
    const [catsOptions, setCatsOptions] = useState<
        { value: string; label: string }[]
    >([])
    const [EstablishementOptions, setEstablishementOptions] = useState([
        { value: '0', label: 'Aucun établissement trouvés', id: 'x' },
    ])
    const [reg, setReg] = useState<iRegistration>({} as iRegistration)
    const [clubOptions, setClubOptions] = useState([
        { value: '0', label: 'Aucun club trouvé', id: 'x' },
    ])
    useEffect(() => {
        getCategories().then((cats) => setCatsOptions(cats))
        axios
            .get(`/establishement/inscription`)
            .then((res) => {
                setEstablishementOptions(() =>
                    res.data.map(
                        (e: {
                            rneCode: string
                            name: string
                            id: string
                            city: string
                        }) => {
                            return {
                                value: e.rneCode,
                                label: `${e.name} (${e.city})`,
                                id: e.id,
                            }
                        }
                    )
                )

                axios
                    .get(`/clubs/inscription`)
                    .then((res) => {
                        setClubOptions(() =>
                            res.data.map(
                                (e: {
                                    name: string
                                    id: string
                                    city: string
                                }) => {
                                    return {
                                        value: e.id,
                                        label: `${e.name} (${e.city})`,
                                        id: e.id,
                                    }
                                }
                            )
                        )
                    })
                    .catch()
                if (lastRegistration) {
                    // reg.establishment = {
                    //     rneCode: lastRegistration?.establishment?.rneCode || '',
                    // } as iEstablishementWContact
                    reg.categoryName = lastRegistration.category.name
                }
            })
            .catch()
    }, [])

    const customStyles = {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        menuList: (base: any) => ({
            ...base,
            maxHeight: 200,
        }),
    }
    const customStylesShort = {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        menuList: (base: any) => ({
            ...base,
            maxHeight: 140,
        }),
    }
    const steps = ['type', 'infos']

    function nextStep() {
        console.log(reg)
        axios
            .post('/registrations/renew', reg)
            .then((res) => {
                console.log(res)
                closeModal()
            })
            .catch((e) => {
                console.log(e)
                toast.error(e.response.data.message)
            })
    }
    return (
        <div
            style={{
                // maxHeight: '600px',
                overflowY: 'scroll',
                minHeight: '400px',
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
            }}
        >
            <h1>Renouvellement de l'inscription</h1>
            <div>
                <span className="inputs-title-insc required">Catégorie</span>
                <Select
                    options={catsOptions}
                    isSearchable={true}
                    placeholder=""
                    styles={customStyles}
                    onChange={(e) => {
                        setReg({
                            ...reg,
                            categoryName: e?.value || '',
                        })
                    }}
                    value={
                        catsOptions.find(
                            (option) => option.value === reg.categoryName
                        ) || null
                    }
                    required
                />
            </div>
            <div>
                <span className="inputs-title-insc">
                    Etablissement (si quart de finale passé en établissement
                    scolaire)
                </span>
                <Select
                    options={EstablishementOptions}
                    isSearchable={true}
                    placeholder=""
                    styles={customStylesShort}
                    onChange={(e) => {
                        console.log(e)
                        if (reg.club && e?.value) {
                            delete reg.club
                        }
                        setReg({
                            ...reg,
                            establishment: {
                                rneCode: e?.value || '',
                            } as iEstablishementWContact,
                        })
                    }}
                    value={
                        EstablishementOptions.find(
                            (option) =>
                                option.value === reg?.establishment?.rneCode
                        ) || null
                    }
                    required
                    isClearable
                />
            </div>
            <div>
                <span className="inputs-title-insc">
                    Club (pour les membres de clubs uniquement)
                </span>
                <Select
                    options={clubOptions}
                    isSearchable={true}
                    placeholder=""
                    styles={customStylesShort}
                    onChange={(e) => {
                        console.log(e)
                        if (reg.establishment && e?.value) {
                            delete reg.establishment
                        }
                        setReg({
                            ...reg,
                            club: {
                                id: e?.value || '',
                            },
                        })
                    }}
                    value={
                        clubOptions.find(
                            (option) => option.value === reg.club?.id
                        ) || null
                    }
                    required
                    isClearable
                />
            </div>
            <button
                className="buttons-blue  part-button"
                style={{ marginTop: 30 }}
                onClick={() => nextStep()}
            >
                Confirmer
            </button>
        </div>
    )
}

export default RenewInscriptionModal
