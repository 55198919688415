import { useEffect, useState } from 'react'
import Table, { PageObject } from '../components/Table'
import './schoolParticipant.css'
import { useNavigate } from 'react-router-dom'
import Modal from '../components/Modal'
import { eStatus } from '../types/enums'
import { iRegistrationWithUser, iSchoolUser, iUser } from '../types/user'
import axios from '../axios/axios'

import AddUserByExcel from '../components/modals/AddUserByExcel'
import AddUser from '../components/modals/AddUser'
import Select from 'react-select'
import IQuestionnaire from '../types/IQuestionnaire'
import ParticipantLayout from './layouts/ParticipantLayout'
import constSchoolParticipant from '../types/tables/constSchoolParticipant'
import { IReduceParticipant } from '../types/IParticipant'
import { filterOptions } from '../types/filters/filtersOptionsSchoolParticipants'
import { modifyModal } from './admin/actionModals/modifyModal'
import { confirmRegistrationModal } from './admin/actionModals/confirmRegistrationModal'
import { addParticipant } from '../actions/addParticipant'
import { getUsers } from '../helpers/getUsers'
import { IfilterFormat, typeFilterFormat } from '../types/IfilterFormat'

function SchoolParticipant() {
    const [pages, setPages] = useState<PageObject>({
        pages: 1,
        actual: 1,
    })
    const [years, setYears] = useState<number[]>([])

    const [triggerEffect, setTriggerEffect] = useState(false)
    const [data, setData] = useState<iSchoolUser | null>(null)
    const [successMsg, setSuccessMsg] = useState<string>('')
    const [selectedYear, setSelectedYear] = useState<number>(2024)
    const [activeYear, setActiveYear] = useState<number>(2025)
    const [establishementData, setEstablishementData] = useState<
        IReduceParticipant[] | null
    >(null)

    const [sessions, setSessions] = useState<IfilterFormat[]>([
        { value: '', label: 'Aucune' },
    ])
    const [selectedSession, setSelectedSession] =
        useState<typeFilterFormat>(null)
    const [filterPrimary, setFilterPrimary] = useState<typeFilterFormat>(null)
    const [filterSecondary, setFilterSecondary] =
        useState<typeFilterFormat>(null)

    const [ModalActive, setModalActive] = useState(false)
    const [modalContent, setModalContent] = useState<
        JSX.Element | string | null
    >(null)

    useEffect(() => {
        document.title = 'Participants'

        // get all years with one participant registration and the current year
        axios.get(`registrations/establishements/old`).then((res) => {
            setYears(res.data)
            setSelectedYear(res.data[res.data.length - 1])
        })
    }, [])

    useEffect(() => {
        axios.get(`questionnaire/visible/${selectedYear}`).then((res) => {
            const sess = res.data.map((sess: IQuestionnaire) => ({
                value: sess.id,
                label: sess.name,
            }))
            setSessions([{ value: '', label: 'Aucune' }, ...sess])
            setSelectedSession({
                value: '',
                label: 'Aucune',
            })
            // if (res.data.length <= 0) {
            //     setSelectedSession({
            //         value: '',
            //         label: 'Aucune',
            //     })
            // } else {
            //     setSelectedSession({
            //         value: res.data[0].id,
            //         label: res.data[0].name,
            //     })
            // }
        })
    }, [selectedYear])
    useEffect(() => {
        if (!selectedYear) return
        getUsers(
            pages,
            filterPrimary,
            filterSecondary,
            selectedSession,
            selectedYear
        )
            .then((res) => {
                axios.get('configurations/public/ACTIVE_YEAR').then((res) => {
                    setActiveYear(res.data.value)
                })
                if (
                    pages.pages !== res.data.meta.totalPages ||
                    pages.actual !== res.data.meta.currentPage
                ) {
                    setPages({
                        pages: res.data.meta.totalPages,
                        actual: res.data.meta.currentPage,
                    })
                }
                setEstablishementData(
                    res.data.data.map((user: iSchoolUser) => ({
                        ...user,
                        grade: user?.results?.[0]?.grade,
                        weightedGrade: user?.results?.[0]?.weightedGrade,
                        category: user?.registrations?.[0]?.category?.name,
                        status: user.registrations?.[0]?.status,
                        examCenter: {
                            ...user?.examCenter,
                            display:
                                (user?.examCenter?.name || '') +
                                (user?.examCenter?.zipCode
                                    ? ` (${user?.examCenter?.zipCode})`
                                    : ''),
                        },
                    }))
                )
            })
            .catch((err) => {
                console.log(err)
            })
    }, [
        pages.actual,
        triggerEffect,
        filterPrimary,
        filterSecondary,
        selectedSession,
        pages,
        selectedYear,
    ])

    const navigate = useNavigate()

    const Pagination = (page: number) => {
        setPages({
            pages: pages.pages,
            actual: page,
        })
    }

    const deleteParticipant = (user: iUser) => {
        console.log('USER', user)
        setModalContent(
            <>
                <span>
                    Êtes vous sûr de vouloir supprimer cet participant ?
                </span>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off school-button-border-off"
                        onClick={() => setModalActive(false)}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue school-button"
                        onClick={async () => await detachRegistration(user)}
                    >
                        Confirmer
                    </button>
                </div>
            </>
        )
        setModalActive(true)
    }

    function detachRegistration(user: iUser) {
        console.log(user)
        axios
            .delete('/registrations/' + user.registrations?.[0].id)
            .then((res) => {
                console.log(res.data)
            })
            .then(() => {
                setModalActive(false)
                setTriggerEffect(!triggerEffect)
            })
    }

    const Excel = () => {
        setModalContent(
            <AddUserByExcel
                close={() => setModalActive(false)}
                importSuccess={(msg) => {
                    setSuccessMsg(msg)
                    setTimeout(() => setTriggerEffect(!triggerEffect), 700)
                    setModalActive(false)
                }}
            />
        )
        setModalActive(true)
    }

    const addNewUser = () => {
        setModalContent('createNewUser')
        setData({} as iSchoolUser)
        setModalActive(true)
    }

    return (
        <ParticipantLayout color="red">
            <div className="admin-container">
                <div className="navbar">
                    <div id="button-left-schoolparticipant" className="">
                        <button
                            className="buttons-white school-button-white"
                            onClick={() =>
                                navigate('/Etablissement/Informations')
                            }
                        >
                            retour
                        </button>
                    </div>
                    <div id="button-right-schoolparticipant">
                        <button
                            className="buttons-blue school-button"
                            onClick={() => Excel()}
                        >
                            Importer un excel
                        </button>
                        <button
                            className="buttons-blue school-button"
                            onClick={() => addNewUser()}
                        >
                            Ajouter un qualifié
                        </button>
                    </div>
                </div>
                {successMsg != '' ? (
                    <div className="messages">
                        <div className="succesful-upload">{successMsg}</div>
                    </div>
                ) : (
                    <></>
                )}
                {years.map((year) => {
                    return (
                        <button
                            key={year}
                            className={
                                year == selectedYear
                                    ? 'buttons-blue school-button'
                                    : 'buttons-white school-button-white'
                            }
                            onClick={() => {
                                setSelectedYear(year)
                            }}
                        >
                            {year}
                        </button>
                    )
                })}
                <div
                    className="w-100"
                    style={{ paddingInline: 17, display: 'flex', gap: 20 }}
                >
                    <div className="insc-inputs">
                        <span className="inputs-title-insc ">
                            Inclure les notes de l'étape
                        </span>
                        <Select
                            options={sessions}
                            placeholder=""
                            onChange={(e) => {
                                setSelectedSession(
                                    e as unknown as IfilterFormat
                                )
                            }}
                            value={selectedSession}
                        />
                    </div>
                    <div className="insc-inputs">
                        <span className="inputs-title-insc ">
                            Ordonner par (primaire)
                        </span>
                        <Select
                            options={filterOptions}
                            placeholder=""
                            onChange={(e) => {
                                setFilterPrimary(e as unknown as IfilterFormat)
                            }}
                            value={filterPrimary}
                        />
                    </div>
                    <div className="insc-inputs">
                        <span className="inputs-title-insc">
                            Ordonner par (secondaire)
                        </span>
                        <Select
                            options={filterOptions}
                            placeholder=""
                            onChange={(e) => {
                                setFilterSecondary(
                                    e as unknown as IfilterFormat
                                )
                            }}
                            value={filterSecondary}
                        />
                    </div>
                </div>

                <div className="page-body">
                    <Table
                        titles={
                            selectedSession && selectedSession.value !== ''
                                ? [
                                      ...constSchoolParticipant,
                                      {
                                          name: 'Note',
                                          isSortable: false,
                                          filters: null,
                                          index: 'grade',
                                      },
                                      {
                                          name: 'Coefficient',
                                          isSortable: false,
                                          filters: null,
                                          index: 'weightedGrade',
                                      },
                                      {
                                          name: "Centre d'épreuve",
                                          isSortable: false,
                                          filters: null,
                                          index: 'examCenter.display',
                                      },
                                  ]
                                : constSchoolParticipant
                        }
                        data={establishementData}
                        color="--rose-vif100"
                        pagination={Pagination}
                        filter={() => {}}
                        page={pages}
                        actions={{
                            modify: {
                                action: (participant) =>
                                    modifyModal(
                                        participant,
                                        setModalContent,
                                        setModalActive,
                                        setTriggerEffect,
                                        triggerEffect,
                                        'school'
                                    ),
                            },
                            delete: {
                                action: deleteParticipant,
                                showCondition: () =>
                                    +activeYear === +selectedYear,
                            },
                            confirm: {
                                action: (participant) =>
                                    confirmRegistrationModal(
                                        selectedYear,
                                        participant,
                                        setModalContent,
                                        setModalActive,
                                        triggerEffect,
                                        setTriggerEffect,
                                        'school'
                                    ),
                                showCondition: (user: iSchoolUser) =>
                                    user.registrations?.[0]?.status ===
                                        eStatus.PENDING &&
                                    +activeYear === +selectedYear,
                            },
                        }}
                        margin="150"
                    />
                </div>

                {typeof modalContent === 'string' &&
                modalContent === 'createNewUser' ? (
                    <Modal isActive={ModalActive} setActive={setModalActive}>
                        <AddUser
                            from="establishment"
                            close={() => setModalActive(false)}
                            setData={(data: iSchoolUser) => {
                                console.log(data)
                                setData(data)
                            }}
                            confirm={(e) => {
                                if (
                                    data?.birthday != undefined &&
                                    data.categoryName != undefined &&
                                    data.languageName != undefined &&
                                    data.contact.lastname != undefined &&
                                    data.contact.firstname != undefined &&
                                    data.contact.email != undefined
                                ) {
                                    addParticipant(data ?? ({} as iSchoolUser))
                                    setModalActive(false)
                                    setTimeout(
                                        () => setTriggerEffect(!triggerEffect),
                                        700
                                    )
                                } else {
                                    console.log('Données non conformes !')
                                }
                            }}
                            data={data as iSchoolUser}
                        />
                    </Modal>
                ) : (
                    <Modal
                        isActive={ModalActive}
                        setActive={() => {
                            setModalActive(false)
                            setTimeout(
                                () => setTriggerEffect(!triggerEffect),
                                500
                            )
                        }}
                    >
                        {modalContent}
                    </Modal>
                )}
            </div>
        </ParticipantLayout>
    )
}
export default SchoolParticipant
