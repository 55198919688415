import axios from '../../axios/axios'
import { useEffect, useState } from 'react'
import NavbarAdmin from '../../components/NavbarAdmin'
import Table, { TableStyle, PageObject } from '../../components/Table'
import './adminEtablissement.css'
import UpdateEstablishment from '../../components/modals/UpdateEstablishment'
import Modal from '../../components/Modal'
import { eestablishements, eStatus } from '../../types/enums'
import * as XLSX from 'xlsx'
import {
    iEstablishementUser,
    iEstablishementWContact,
    iEstablishment,
} from '../../types/user'
import { toast } from 'react-toastify'

export enum typeStatusEstablishement {
    A_PAYE = 'À payé',
    NON = 'Non',
}

interface iEstablishementWContactAndStatus extends iEstablishementWContact {
    accountStatus: string
}

function AdminEtablissement() {
    const [pages, setPages] = useState<PageObject>({
        pages: 1,
        actual: 1,
    })
    const [search, setSearch] = useState('')

    const [filteredData, setFilteredData] = useState<
        iEstablishementWContactAndStatus[] | null
    >(null)
    const [triggerEffect, setTriggerEffect] = useState(false)
    const [selectedYear, setSelectedYear] = useState<string>('')

    useEffect(() => {
        // get current year
        axios.get('configurations/ACTIVE_YEAR').then((res) => {
            setSelectedYear(res.data.value)
        })
    }, [])
    useEffect(() => {
        document.title = 'Établissements'
        if (selectedYear)
            axios
                .get(
                    '/establishement?limit=10&page=' +
                        pages.actual +
                        (search !== '' ? '&search=' + search : '')
                )
                .then((res) => {
                    setPages({
                        pages: res.data.meta?.totalPages ?? 1,
                        actual:
                            (res.data.meta?.currentPage >
                            res.data.meta?.totalPages
                                ? res.data.meta?.totalPages
                                : res.data.meta?.currentPage) ?? 1,
                    })

                    setFilteredData(
                        res.data.data.map((data: iEstablishementWContact) => ({
                            ...data,
                            accountStatus:
                                data?.registrations &&
                                data.registrations?.reduce(
                                    (acc, elem) =>
                                        elem.year === +selectedYear
                                            ? elem.status
                                            : acc,
                                    eStatus.PENDING
                                ),
                        }))
                    )
                })
        //console.log(data)
    }, [pages.actual, triggerEffect, search, selectedYear])

    async function getAllEstablishments() {
        return axios
            .get('/establishement?limit=0')
            .then((res) => {
                return res.data
            })
            .catch((error) => {
                console.error(error)
                return {}
            })
    }

    const [ModalActive, setModalActive] = useState(false)
    const [modalType, setModalType] = useState<JSX.Element | null>(null)

    const testTitles: TableStyle[] = [
        {
            name: 'ID / RNE',
            isSortable: false,
            filters: null,
            index: 'rneCode',
        },
        { name: 'Nom', isSortable: true, filters: null, index: 'name' },
        {
            name: 'Ville',
            isSortable: false,
            filters: null,
            index: 'city',
        },
        { name: 'Type', isSortable: true, filters: null, index: 'type' },

        {
            name: 'Prénom correspondant',
            isSortable: false,
            filters: null,
            index: 'user.contact.firstname',
        },
        {
            name: 'Nom correspondant',
            isSortable: false,
            filters: null,
            index: 'user.contact.lastname',
        },
        {
            name: 'Email correspondant',
            isSortable: false,
            filters: null,
            index: 'user.contact.email',
        },
        {
            name: 'Téléphone correspondant',
            isSortable: false,
            filters: null,
            index: 'user.contact.phone',
        },
        {
            name: 'Nombre de participants',
            isSortable: false,
            filters: null,
            index: 'estimatedParticipants',
        },
        {
            name: 'Centre de demie finale',
            isSortable: false,
            filters: null,
            index: 'proposeToBeHalfExamCenter',
        },
        {
            name: 'Visible',
            isSortable: false,
            filters: null,
            index: 'visible',
        },
        {
            name: 'Adhérent ? ',
            isSortable: true,
            filters: [
                typeStatusEstablishement.NON,
                typeStatusEstablishement.A_PAYE,
            ],
            index: 'accountStatus',
        },
        /*{ name: 'Identifiant', isSortable: false, filters: null, index: 'id' },*/
    ]

    const Pagination = (page: number) => {
        setPages({
            pages: pages.pages,
            actual: page,
        })
    }

    const filter = (filter: string, value: string) => {
        //console.log(filter)
    }

    const resetEstablishments = () => {
        axios
            .patch('/establishement/reset')
            .then((res) => {
                toast.success(
                    'Les établissements ont été réinitialisés. Rechargez la page.'
                )
            })
            .catch((err) => {
                toast.error(
                    'Une erreur est survenue lors de la réinitialisation des établissements'
                )
            })
    }

    const exportExcel = () => {
        setModalType(
            <>
                <span>
                    Voulez-vous exporter les donnees des établissements ?
                </span>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off admin-border-off"
                        onClick={() => setModalActive(false)}
                    >
                        Annuler
                    </button>

                    <button
                        className="buttons-blue blue-admin"
                        onClick={async () => await exportExcelFile()}
                    >
                        Confirmer
                    </button>
                </div>
            </>
        )
        setModalActive(true)
    }

    const exportExcelFile = async () => {
        const response = await getAllEstablishments()
        const result: {
            "Nom d'Établissement": string
            Ville: string
            Rue: string
            Pays: string
            'Code Postal': string
            Type: string
            'Code RNE': string
            'Nom du Correspondant': string
            'Prénom du Correspondant': string
            'Email du Correspondant': string
            'Téléphone du Correspondant': string
            Adhésion: string
            'Nombre de participants': string
            "Proposition d'accueillir la demi-finale": boolean
            Visible: boolean
        }[] = []
        console.log(response)
        response.data.forEach((res: iEstablishementWContact) => {
            result.push({
                "Nom d'Établissement": res.name ?? '',
                Ville: res.city ?? '',
                'Code Postal': res.zipCode ?? '',
                Rue: res.street ?? '',
                Pays: res.country ?? '',
                Type: res.type ?? null,
                'Code RNE': res.rneCode ?? null,
                'Nom du Correspondant': res.user?.contact?.lastname ?? '',
                'Prénom du Correspondant': res.user?.contact?.firstname ?? '',
                'Email du Correspondant': res.user?.contact?.email ?? '',
                'Téléphone du Correspondant': res.user?.contact?.phone ?? '',
                Adhésion:
                    '' +
                    res.registrations?.reduce(
                        (acc, elem) =>
                            elem.year === +selectedYear ? elem.status : acc,
                        eStatus.PENDING
                    ),
                'Nombre de participants': res.estimatedParticipants
                    ? '' + res.estimatedParticipants
                    : '-',
                "Proposition d'accueillir la demi-finale":
                    res.proposeToBeHalfExamCenter ?? false,
                Visible: res.visible ?? false,
            })
        })
        const worksheet = XLSX.utils.json_to_sheet(result)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Etablissements')
        XLSX.writeFile(workbook, 'Etablissements.xlsx')
        setModalActive(false)
    }

    const modify = (establishment: iEstablishementWContact) => {
        console.log('EST', establishment)
        setModalType(
            <>
                <UpdateEstablishment
                    close={() => setModalActive(false)}
                    confirm={async (dataToSend) =>
                        await updateEstablishment(establishment.id, dataToSend)
                    }
                    initialValue={establishment}
                />
            </>
        )
        setModalActive(true)
    }

    const updateEstablishment = async (
        id: string,
        dataSent: {
            name: string
            type: eestablishements
            rneCode: string
            street: string
            city: string
            zipCode: string
            country: string
            estimatedParticipants: number
            visible: boolean
        }
    ) => {
        console.log(dataSent)
        const requestBody = {
            name: dataSent?.name,
            type: dataSent?.type,
            rneCode: dataSent?.rneCode,
            street: dataSent?.street,
            city: dataSent?.city,
            zipCode: dataSent?.zipCode,
            country: dataSent?.country,
            visible: dataSent?.visible,
            estimatedParticipants: dataSent?.estimatedParticipants,
        }
        console.log(requestBody)
        await axios.patch('/establishement/' + id, requestBody).then((res) => {
            console.log(res.data)
        }).catch
        setModalActive(false)
        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
    }

    const confirmRegistrationEstablishment = (
        establishement: iEstablishementUser
    ) => {
        setModalType(
            <>
                <span>Êtes vous sûr de vouloir confirmer ce compte ?</span>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off admin-border-off"
                        onClick={() => setModalActive(false)}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue blue-admin"
                        onClick={async () =>
                            await confirmEstablishment(establishement.id)
                        }
                    >
                        Confirmer
                    </button>
                </div>
            </>
        )
        setModalActive(true)
    }

    const confirmEstablishment = async (id: string) => {
        const requestBody = {
            accountStatus: eStatus.CONFIRMED,
        }
        console.log(
            `/registrations/validate/establishment/${id}/${selectedYear}`,
            requestBody
        )
        await axios
            .patch(
                `/registrations/validate/establishment/${id}/${selectedYear}`,
                requestBody
            )
            .then((res) => {
                console.log(res.data)
            }).catch
        setModalActive(false)
        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
    }

    return (
        <>
            <div className="admin-container">
                <div className="navbar">
                    <NavbarAdmin active="etablissements" />
                </div>
                <div className="page-body" style={{ width: '100%' }}>
                    <div className="font-italic text-xs mb-10">
                        Ici vous retrouverez l'ensemble des établissements
                        inscrits sur la plateforme. Il n'est pas possible d'en
                        supprimer. <br />
                        Le bouton "Confirmer" valide l'adhésion de
                        l'établissement sur l'année en cours (actuellement{' '}
                        {selectedYear}), les informations sont les plus récentes
                        indiquées par l'établissement, hormis le statut
                        d'adhésion les informations des précédentes années ne
                        sont plus disponibles. <br />
                        Réinitialiser les établissements passe tous les
                        établissements à l'état "Non visible" avec un nombre de
                        participants à 0.
                    </div>
                    <div className="flex flex-row justify-between">
                        <div
                            style={{
                                display: 'flex',
                                gap: 20,
                                justifyContent: 'center',
                                alignContent: 'baseline',
                                flexWrap: 'wrap',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <div className="insc-inputs" id="admin-search">
                                    <input
                                        className="insc-input"
                                        type="text"
                                        placeholder="Rechercher un établissement"
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <button
                                className="buttons-blue blue-admin"
                                onClick={exportExcel}
                            >
                                Exporter
                            </button>
                        </div>
                    </div>
                    <Table
                        titles={testTitles}
                        data={filteredData}
                        color="--orange100"
                        pagination={Pagination}
                        filter={filter}
                        page={pages}
                        actions={{
                            modify: {
                                action: modify,
                            },
                            confirm: {
                                action: confirmRegistrationEstablishment,
                                showCondition: (
                                    row: iEstablishementWContactAndStatus
                                ) => row.accountStatus === eStatus.PENDING,
                            },
                        }}
                        margin="30"
                    />
                </div>
                <Modal
                    isActive={ModalActive}
                    setActive={setModalActive}
                    children={modalType}
                ></Modal>
                <button
                    className="buttons-blue blue-admin"
                    onClick={resetEstablishments}
                >
                    Réinitialiser les établissements (irréversible)
                </button>
            </div>
        </>
    )
}
export default AdminEtablissement
